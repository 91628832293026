body {
    margin: 0;
}

#root {
    height: 100vh
}

#root > div {
    height: 100%;
}

@keyframes fade {
    from { opacity: 1.0; }
    50% { opacity: 0.5; }
    to { opacity: 1.0; }
}

@-webkit-keyframes fade {
    from { opacity: 1.0; }
    50% { opacity: 0.5; }
    to { opacity: 1.0; }
}

/* SCROLLBAR */

/* width */
::-webkit-scrollbar {
    width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(78, 79, 81, .1);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(78, 79, 81, .6);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #4E4F51;
}

